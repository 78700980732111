import "./App.scss";

import React, {useState, useEffect, useRef} from "react";
import Lottie from "lottie-react";
import Loader from "react-loaders";

import animationHello from "./assets/Animation-Hello.json";
import Me from "./assets/me.png";
import NextCloudIcon from "./assets/nextcloud_icon.png";
import PortfolioIcon from "./assets/portfolio_icon.png";
import MySocialNetworkIcon from "./assets/mysocialnetwork_icon.png";
import Home from "./assets/marseille.png";

function App() {
    const [rotate, setRotate] = useState(false);
    const [loading, setLoading] = useState(true);
    const [fadeIn, setFadeIn] = useState(false);

    const lottieRef = useRef(null);

    useEffect(() => {
        const loadingTimeout = setTimeout(() => {
            setLoading(false);
            setFadeIn(true);
        }, 3000);

        return () => {
            clearTimeout(loadingTimeout);
        };
    }, []);
    const rotateImage = () => {
        setRotate(true);
        setTimeout(() => setRotate(false), 1000);

    };

    const handleComplete = () => {
        if (lottieRef.current) {
            lottieRef.current.goToAndStop(90, true);
        }
    };

    return (
        <>
            {loading ? (
                <Loader type="ball-spin-fade-loader" active />
            ) : (
                <div className={`app-container ${fadeIn ? 'fadeIn' : ''}`}>
                    <div className={`app-header ${fadeIn ? 'fadeIn' : ''}`}>
                        <img
                            className={`image ${rotate ? "rotate" : ""}`}
                            src={Me}
                            alt="Me"
                            onClick={() => {
                                rotateImage();
                            }}
                        />
                        <Lottie
                            className="lottie-animation"
                            animationData={animationHello}
                            loop={1}
                            onComplete={handleComplete}
                            lottieRef={lottieRef}

                        />
                    </div>
                    <div className={`app-body ${fadeIn ? 'fadeIn' : ''}`}>
                        <div
                            className="nextcloud-btn-container"
                            onClick={() => window.open("https://nextcloud.luctatu.fr/")}
                        >
                            <img
                                className="nextcloud-icon"
                                src={NextCloudIcon}
                                alt="My NextCloudIcon"
                            />
                            <h1 className="tile">My NextCloud</h1>
                        </div>
                        <div
                            className="portfolio-btn-container"
                            onClick={() => window.open("https://portfolio.luctatu.fr/")}
                        >
                            <img
                                className="portfolio-icon"
                                src={PortfolioIcon}
                                alt="My portfolio"
                            />
                            <h1 className="tile">My Portfolio</h1>
                        </div>
                        <div
                            className="mySocialNetwork-btn-container"
                            onClick={() => window.open("https://mysocialnetwork.luctatu.fr/")}
                        >
                            <img
                                className="mySocialNetwork-icon"
                                src={MySocialNetworkIcon}
                                alt="My Social Network"
                                onClick={() => window.open("https://msn.luctatu.fr/swagger-ui/index.html")}
                            />
                            <h1 className="tile">My Social Network</h1>
                        </div>
                    </div>
                    <div className={`app-footer ${fadeIn ? 'fadeIn' : ''}`}>
                        <img className="home" src={Home} alt="Home" />
                    </div>
                </div>
            )}
        </>
    );
}

export default App;
